<template>
  <div class="record">
    <nav-bar :title="$t('链游记录')" />

    <div class="invest">
      <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false"
        offset="10" @load="onLoad">
        <div class="invest-item" v-for="item in list" :key="item.id">
          <div class="cell">
            <div>{{ $t('链游结果') }}：{{ item.resultValue }}</div>
            <div class="m-color">{{ $t(item.stateName) }} ({{ $t(item.stateSettleName) }})</div>
          </div>
          <div class="cell">
            <div>{{ $t('我的链游') }}：{{ $t(item.xiazhuTypeName) }}</div>
            <div>{{ $t('链游') }}{{ item.resultCoinName }}：{{ item.xiazhuAmountString }}</div>
          </div>
          <div class="cell">
            <!-- <div @click="toUrl">{{ $t('区块高度') }}：{{ item.blockNumber }}</div> -->
            <div></div>
            <div>{{ abbr(item.blockHash, 4, 8) }}</div>
          </div>
          <!-- <div class="cell" v-if="item.state == 0">
            <view class="btn" @click="transfer">转账</view>
          </div> -->
        </div>
      </van-list>

      <empty v-if="list.length == 0"></empty>
    </div>

    <!-- txid -->
    <q-dialog v-model="showTxid" persistent>
      <q-card class="q-dialog-plugin" style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">{{ $t('交易广播成功') }}</div>
          <div class="text-body2">Txid</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="input-wrap">
            <q-input borderless v-model="txid" dense :input-style="{ color: '#fff' }" style="width: 100%;" readonly>
              <template v-slot:append>
                <q-icon name="content_copy" style="font-size: 16px;" color="white" @click="copy(txid)" />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <div style="padding: 0 16px 16px;">
          <div class="btn" style="margin: 0;" @click="showTxid = false">{{ $t('确定') }}</div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from 'vue';
import { List } from "vant";
import CustomComponent from "@/components/CustomComponent";
import ExtractChineseWords from "@/assets/js/language/tool/extract-chinese-words.js";

import Web3Helper from "@/assets/js/web3helper.js";

export default {
  name: '',
  components: {
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      list: ref([]),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),

      inAddress: ref(""),
      showTxid: ref(false),
      txid: ref(""),
      web3Helper: ref({}),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getdata()
      this.getJingcaiSetting()
      this.web3Helper = new Web3Helper();
    }
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    toUrl() {
      window.open('https://bscscan.com/')
    },
    getJingcaiSetting() {
      let _this = this
      _this.$request.post(
        "api/JingcaiSetting/Get",
        {
          userid: _this.us.userid,
          uid: _this.us.id,
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            _this.inAddress = data.address
          }
        }
      )
    },
    // transfer() {
    //   let _this = this
    //   if (this.inAddress == '') {
    //     this.$q.dialog({
    //       component: CustomComponent,
    //       componentProps: {
    //         messages: ['未设置转账指定地址'],
    //         persistent: true,
    //       }
    //     });
    //     return
    //   }
    //   let payWei = _this.web3Helper.toWei(0.01, 18);
    //   let outWei = 0;

    //   _this.$q.loading.show({
    //     message: _this.$i18n.t("正在交易中...")
    //   })

    //   _this.web3Helper.transfer(_this.us.address, _this.inAddress, payWei.toString()).then((txid) => {
    //     _this.$q.loading.hide();
    //     _this.txid = txid
    //     _this.showTxid = true
    //     // _this.balance(_this.us.address)
    //   }, (err) => {
    //     _this.$q.loading.hide();
    //     //过滤4001,4001=拒绝签名
    //     if (err.code != 4001) {
    //       _this.$q.dialog({
    //         component: CustomComponent,
    //         componentProps: {
    //           title: err.code,
    //           messages: [err.message],
    //           persistent: true,
    //         }
    //       });
    //     }
    //   });
    // },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/JingcaiOrder/List",
        {
          userid: _this.us.userid,
          uid: _this.us.id,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          state: 99
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          if (data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          // console.log(data)
          _this.list.push(...data)
        }
      )
    },
    getTitle(title) {
      let result = ""
      let arr = ExtractChineseWords(title)
      arr.forEach(item => {
        result += this.$i18n.t(item)
      });
      return result
    },
  }
}
</script>

<style scoped>
.record {
  padding: 10px;
}

.invest-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: #121C2E;
}

.cell {
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
}

.cell-btn {
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 3px 15px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 6px;
}

.q-dialog-plugin {
  max-height: calc(100vh - 108px);
  background: #000;
  color: #fff;
  border: 2px solid #f56ef9;
}

.dialog-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  padding: 16px;
}
</style>